import React, { Fragment, useContext, useEffect, useState } from "react"
import Layout from "../components/Layout/Pages"
import { navigate } from "gatsby"
import {
  getAssetListing,
  getAssetListingGroups,
  getProductListing,
  getSingleProduct,
  getStatusListingByBrand,
  sessToken,
  trackVisits,
} from "../helpers/DrupalHelper"
import { classNames } from "../utils"
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Tab,
  Transition,
} from "@headlessui/react"
import BrandSingleCard from "../components/Cards/BrandSingleCard"
import BrandSingleCardProfile from "../components/Cards/BrandSingleCardProfile"
import ProductGridCard from "../components/Cards/ProductGridCard"
import { CheckIcon, ThumbUpIcon, UserIcon } from "@heroicons/react/solid"
import { getBrandSingle } from "../helpers/DrupalHelper"
import StateContext from "../context/state/StateContext"
import ActivityFeedCard from "../components/Cards/ActivityFeedCard"
import AssetsCard from "../components/Cards/AssestCard"
import AlbumCard from "../components/Cards/AlbumCard"
import BannerFull from "../components/Commons/BannerFull"
import Container from "../components/Container"
import Button from "../components/Elements/Button"
import ActivityFeedCreateCard, {
  ActivityFeedCreateModal,
} from "../components/Cards/ActivityFeedCreateCard"
import ProductsSideBarFilter from "../components/Elements/ProductsSideBarFilter"
import ProductsTabBrand from "../components/Tabs/ProductsTabBrand"
import AssetsTabBrand from "../components/Tabs/AssetsTabBrand"
import AssetsSideBarFilter from "../components/Elements/AssetsSideBarFilter"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import ActivityFeedListing from "../templates/ActivityFeed"
import Skeleton from "../components/Skeleton"
import AccessDenied from '../components/AccessDenied'
const Tabs = props => {
  const { state } = useContext(StateContext)
  const { token } = state
  let { newActivity } = props

  const [currentTab, setCurrentTab] = useState("Company")
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [activityData, setActivityData] = useState(null)
  const [assetsData, setAssetsData] = useState([])
  const [productsData, setProductsData] = useState([])
  const [filterOpenProducts, setFilterOpenProducts] = useState(false)
  const [filterOpenAssets, setFilterOpenAssets] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingAsset, setLoadingAsset] = useState(true)
  const [firstBodyProducts, setFirstBodyProducts] = useState({})
  const [firstBodyAssets, setFirstBodyAssets] = useState({})
  const [filterBodyProducts, setFilterBodyProducts] =
    useState(firstBodyProducts)
  const [filterBodyAssets, setFilterBodyAssets] = useState(firstBodyAssets)

  useEffect(() => {
    let firstBodyProductsObj = {
      brand: [props.brandId],
      categories: [],
      // featured: null,
      item_per_page: "12",
      offset: "0",
      // product_type: [],
      sort: [{ title: "asc" }],
      tags: [],
    }
    setFirstBodyProducts(firstBodyProductsObj)
    let firstBodyAssetsObj = {
      offset: "0",
      item_per_page: "12",
      categories: [],
      type: ["digital", "physical"],
      sort: [{ title: "asc" }],
      tags: "",
      brand: [props.brandId],
    }
    setFirstBodyAssets(firstBodyAssetsObj)
  }, [])

  useEffect(() => {
    let firstBodyProductsObj = {
      brand: [props.brandId],
      categories: [],
      // featured: null,
      item_per_page: "12",
      offset: "0",
      // product_type: [],
      sort: [{ title: "asc" }],
      tags: [],
    }
    setFirstBodyProducts(firstBodyProductsObj)
    let firstBodyAssetsObj = {
      offset: "0",
      item_per_page: "12",
      categories: [],
      type: ["digital", "physical"],
      sort: [{ title: "asc" }],
      tags: "",
      brand: [props.brandId],
    }
    setFirstBodyAssets(firstBodyAssetsObj)
  }, [props.brandId])

  useEffect(() => {
    if (Object.keys(firstBodyAssets).length > 0)
      setFilterBodyAssets(firstBodyAssets)
    if (Object.keys(firstBodyProducts).length > 0)
      setFilterBodyProducts(firstBodyProducts)
    console.log("being called 1")
  }, [firstBodyAssets, firstBodyProducts])

  const callResetAssets = async () => {
    setLoading(true)
    setFilterBodyAssets(firstBodyAssets)
    let adata = await getAssetListingGroups(token, firstBodyAssets)
    setAssetsData([...adata.results])
    setLoading(false)
  }
  const callResetProduct = async () => {
    setLoading(true)
    setFilterBodyProducts(firstBodyProducts)
    // const res = await getAssetListingGroups(token, {
    //   // page: 1,
    //   offset: "0",
    //   item_per_page: "12",
    //   categories: [],
    //   // type: ["digital", "physical"],
    //   sort: [{ title: "asc" }],
    //   tags: "",
    // })

    let pdata = await getProductListing(token, firstBodyProducts)
    setProductsData([...pdata.results])
    setLoading(false)
  }
  let tabs = [
    // { name: "Activity feed", href: "#", component: false },
    { name: `Assets (${props.totalAssets})`, href: "#", component: true },
    { name: `Products (${props.totalProducts})`, href: "#", component: false },
  ]
  if (props.bio)
    tabs = [
      // { name: "Activity feed", href: "#", component: false },
      { name: "About", href: "#", component: false },
      { name: `Assets (${props.totalAssets})`, href: "#", component: true },
      {
        name: `Products (${props.totalProducts})`,
        href: "#",
        component: false,
      },
    ]
  useEffect(async () => {
    let data = await getStatusListingByBrand(token, props.brandId, 1)
    setActivityData([...data])
  }, [])
  useEffect(() => {
    if (newActivity) {
      setActivityData(v => [...v, newActivity])
    }
  }, [newActivity])
  useEffect(async () => {
    if (Object.keys(filterBodyProducts).length > 0) {
      setLoading(true)

      let filteredProducts = await getProductListing(state.token, {
        ...filterBodyProducts,
        offset: "0",
      })
      setProductsData(filteredProducts.results)
      setLoading(false)
    }
  }, [filterBodyProducts])

  useEffect(async () => {
    if (Object.keys(filterBodyAssets).length > 0) {
      setLoadingAsset(true)
      let filteredAssets = await getAssetListingGroups(state.token, {
        ...filterBodyAssets,
        offset: "0",
      })
      setAssetsData(filteredAssets.results)
      setLoadingAsset(false)
    }
  }, [filterBodyAssets])
  return (
    <div>
      <Menu as="div" className="mx-[15px] md:hidden">
        <Menu.Button className="flex justify-center space-x-1 items-center w-full rounded-[8px] border border-[#EBEBEB] px-4 py-2 bg-white text-reg-16 hover:bg-[#f7f7f7] focus:outline-none">
          <span>Viewing:</span>{" "}
          <span className="text-med-16 !leading-[30px]">
            {tabs[selectedIndex].name}
          </span>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top absolute left-[15px] right-[15px] mt-2 z-[1] rounded-[8px] shadow-lg bg-white ring-1 ring-[#222] focus:outline-none">
            <div className="p-[15px]">
              {tabs.map((item, k) => {
                return (
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => {
                          setSelectedIndex(k)
                        }}
                        className={`
                          ${
                            (active || k === selectedIndex) &&
                            "bg-[#fce3e1] !text-[#E43D30]"
                          } block px-[15px] py-[5px] text-reg-16 !text-[#222] rounded-[8px]
                        `}
                      >
                        {item.name}
                      </a>
                    )}
                  </Menu.Item>
                )
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <Tab.Group
        selectedIndex={selectedIndex}
        defaultIndex={0}
        onChange={setSelectedIndex}
      >
        <Tab.List className="hidden md:flex -mb-px space-x-8 border-b border-[#EBEBEB]">
          {tabs.map((item, k) => (
            <Tab
              key={k}
              className={({ selected }) =>
                classNames(
                  selected
                    ? "border-[#222] text-[#222]"
                    : "border-transparent text-[#222] hover:text-gray-500",
                  "whitespace-nowrap pt-[30px] pb-[28px] px-1 border-b-2 text-med-16"
                )
              }
            >
              {item.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="pt-[30px]">
          {tabs.map((item, k) => {
            if (item.name === "About") {
              return (
                <Tab.Panel key={k}>
                  {/* {posts} */}
                  <div className="max-w-[700px] leading-2 text-reg-16">
                    {props.bio}
                  </div>
                </Tab.Panel>
              )
            } else if (item.name.includes("Products")) {
              return (
                <Tab.Panel key={k}>
                  <div className="mb-8">
                    {/* {posts} */}
                    {/*<div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8">*/}
                    <ProductsTabBrand
                      setFilterBody={setFilterBodyProducts}
                      setFilterOpen={setFilterOpenProducts}
                      body={filterBodyProducts}
                      data={productsData}
                      total={props.totalProducts}
                      loading={loading}
                      setLoading={setLoading}
                      callReset={callResetProduct}
                    />
                  </div>
                </Tab.Panel>
              )
            } else if (item.name.includes("Assets")) {
              return (
                <Tab.Panel key={k}>
                  {/* {posts} */}
                  {/*<div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8">*/}
                  <div className="mb-8">
                    <AssetsTabBrand
                      callReset={callResetAssets}
                      setFilterOpen={setFilterOpenAssets}
                      setFilterBody={setFilterBodyAssets}
                      data={assetsData}
                      body={filterBodyAssets}
                      total={props.totalAssets}
                      loading={loadingAsset}
                      setLoading={setLoadingAsset}
                    />
                  </div>
                </Tab.Panel>
              )
            // } else if (item.name === "Activity feed") {
            //   return (
            //     <Tab.Panel key={k}>
            //       {/* {posts} */}

            //       {activityData !== null ? (
            //         <ActivityFeedListing
            //           serverData={activityData}
            //           {...props}
            //           fromPage={false}
            //         />
            //       ) : (
            //         <div className="w-full overflow-hidden flex flex-col items-center justify-center">
            //           <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
            //         </div>
            //       )}
            //     </Tab.Panel>
            //   )
            } else {
              return null
            }
          })}
        </Tab.Panels>
      </Tab.Group>
      <ProductsSideBarFilter
        open={filterOpenProducts}
        setOpen={setFilterOpenProducts}
        setFilterBody={setFilterBodyProducts}
        fromBrand
        filterBody={filterBodyProducts}
      />
      <AssetsSideBarFilter
        open={filterOpenAssets}
        setOpen={setFilterOpenAssets}
        setFilterBody={setFilterBodyAssets}
        fromBrand
        filterBody={filterBodyAssets}
      />
    </div>
  )
}

export default function BrandSingle({ pageContext, location }) {
  const { state } = useContext(StateContext)
  const { currentUserData } = useContext(AuthenticationContext).authentication
  const [serverData, setServerData] = useState(null)
  const [forbidden,setForbidden]=useState(false)
  useEffect(async () => {
    let array = location.pathname.split("/")
    if (array.length > 2) {
      let nid = array[2]
      let res = await getBrandSingle(state.token, parseInt(nid))
      if (res.status === 200) {
        res = await res.json()
        setServerData({ ...res, id: nid })

        // console.log(res.json(),'res')
        trackVisits(state.token, {
          type: "brand",
          brand: nid,
          user: currentUserData.i,
        })
      }else if(res.status===403){
        setForbidden(true)
      } else {
        navigate("/404")
      }
    } else {
      navigate("/404")
    }
  }, [location])
  const [open, setOpen] = useState(false)
  const [newActivity, setNewActivity] = useState(null)
  let showButton = currentUserData.brands.filter(v => serverData?.id === v.id)

  if(forbidden){
    return <AccessDenied />
  }
  return (
    <Layout metaTitle={!serverData ? "Brand" : serverData.name} metaDesc={""}>
      {serverData !== null ? (
        <>
          {" "}
          <BannerFull
            slim
            // actions={
            //   showButton.length
            //     ? [
            //         {
            //           type: "secondary",
            //           text: "New Status",
            //           onClick: () => setOpen(true),
            //         },
            //         {
            //           type: "primary",
            //           text: "Edit Brand",
            //           onClick: () =>
            //             navigate("/dashboard/my-brands", {
            //               state: {
            //                 editId: serverData.id,
            //               },
            //             }),
            //         },
            //       ]
            //     : false
            // }
            bgImg={serverData.bg_image}
          />
          <Container>
            <div className="grid md:grid-cols-5">
              <div className="-mt-[170px] ml-[15px] md:ml-0 md:-mt-[110px] mb-8">
                <BrandSingleCardProfile
                  brandId={pageContext.nid ? pageContext.nid : serverData.id}
                  {...serverData}
                  token={state.token}
                />
              </div>
              <div className="md:col-span-4 lg:pl-[30px]">
                <Tabs
                  assets={serverData.assets}
                  bio={serverData.bio}
                  totalAssets={serverData.total_assets_and_groups}
                  totalProducts={serverData.total_products}
                  addedActivityData={newActivity}
                  logo={serverData.logo}
                  isUserBrand={showButton.length ? true : false}
                  // brandId={serverData.id}
                  brandId={serverData.id}
                  brandTitle={serverData.name}
                />
              </div>
            </div>
          </Container>
          {showButton.length > 0 && (
            <ActivityFeedCreateModal
              open={open}
              setOpen={setOpen}
              onSave={data => {
                if (data) setNewActivity(data)
              }}
              token={state.token}
              brandId={serverData.id}
              brandTitle={serverData.name}
            />
          )}
        </>
      ) : (
        <>
          <BannerFull slim actions={false} bgImg={"xys"} />
          <Container>
            <div className="grid md:grid-cols-5">
              <div className="-mt-[170px] ml-[15px] md:ml-0 md:-mt-[110px] mb-8">
                <BrandSingleCardProfile loading={true} />
              </div>
              <div className="md:col-span-4 lg:pl-[30px]">
                <div>
                  <Tab.Group defaultIndex={0}>
                    <Tab.List className="hidden md:flex -mb-px space-x-8 border-b border-[#EBEBEB]">
                      <Tab
                        key={0}
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "border-[#222] text-[#222]"
                              : "border-transparent text-[#222] hover:text-gray-500",
                            "whitespace-nowrap pt-[30px] pb-[28px] px-1 border-b-2 text-med-16"
                          )
                        }
                      >
                        <Skeleton height={"18px"} width="50px" />
                      </Tab>
                    </Tab.List>
                    <Tab.Panels className="pt-[30px]">
                      <Tab.Panel key={0}>
                        <ActivityFeedListing loading={true} fromPage={false} />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </div>
            </div>
          </Container>
        </>
      )}
    </Layout>
  )
}
